<template>
  <div>
    
    <div class="card card-custom gutter-b">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <router-link
            to="/verifikasi/master-data/role"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
          <li class="nav-item">
            <a
              class="nav-link"
              role="tab"
              :href="href" 
              @click="navigate"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <span class="svg-icon svg-icon-md svg-icon-success m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Navigation/Left-2.svg"/>
                <!--end::Svg Icon-->
              </span>
              Kembali
            </a>
          </li>
          </router-link>
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              role="tab"
            >
              View Data
            </a>
          </li>
        </ul>
      </div>

      <!--begin::Form-->
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div v-if="dataOri">
                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Kode</label>
                      <div class="col-lg-9 col-xl-7">
                          <b-form-group
                              id="input-group-role-1" 
                              label-for="input-role-1"
                              class="mb-0">
                              <span id="input-role-1" class="form-control form-control-solid">
                                {{ form.role }}
                              </span>
                          </b-form-group>
                      </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Nama Role</label>
                      <div class="col-lg-9 col-xl-7">
                          <b-form-group
                              id="input-group-role-2" 
                              label-for="input-role-2"
                              class="mb-0">
                              <span id="input-role-2" class="form-control form-control-solid">
                                {{ form.name }}
                              </span>
                          </b-form-group>
                      </div>
                  </div>

                  <div class="table-responsive pt-10">
                    <table class="table b-table table-borderless">
                      <thead class="thead-light">
                        <tr>
                          <th class="font-weight-bold text-left " v-for="heading in ['Module', 'Read', 'Create', 'Update', 'Delete']" :key="heading">{{ heading }}</th>
                        </tr>
                      </thead>

                      <tr v-for="(val, name) in form.permissions" :key="name">
                        <td class="">{{ name | title }}</td>
                        <td class="">
                          <b-form-checkbox disabled='disabled' size="lg" v-model="val['view']" ></b-form-checkbox>
                        </td>
                        <td class="">
                          <b-form-checkbox disabled='disabled' size="lg" v-if="!listNotShow.includes(name)" v-model="val['create']" ></b-form-checkbox>
                        </td>
                        <td class="">
                          <b-form-checkbox disabled='disabled' size="lg" v-if="!listNotShow.includes(name)" v-model="val['edit']" ></b-form-checkbox>
                        </td>
                        <td class="">
                          <b-form-checkbox disabled='disabled' size="lg" v-if="!listNotShow.includes(name)" v-model="val['delete']" ></b-form-checkbox>
                        </td>
                      </tr>
                    </table>
                  </div>

              </div>
              <div v-else>
                <BulletListLoader></BulletListLoader>
              </div>          
            </b-tab>
          </b-tabs>
        </div>

    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BulletListLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VerifikasiService from "@/core/services/api/verifikasi/master/role.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "verifikasi-master-data-role-view",
  props: {
    idR: {
      required: true
    }
  },
  components: {
    BulletListLoader
  },
  data() {
    return {
      tabIndex: 0,
      dataOri: null,
      form: {
        role: Number(this.idR),
        name: null,
        permissions: {
            'full_code': {
                'view': false,
            },
            'profil': {
                'view': true,
            },
            'cutting_he': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'afkir_dini': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'verifikasi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'statistik': {
                'view': false,
            },
            'perusahaan': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'farm': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'hatchery': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'peternak': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'peternak_farm': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'wilayah': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            }, 
            'surat_edaran': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'role': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'user': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'nasional_populasi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'nasional_produksi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'nasional_distribusi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
        },
      },
      listNotShow: ['full_code', 'profil', 'statistik'],
      show: true,
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    
    getData () {
      VerifikasiService.getRoleSingle({id: this.idR})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            this.dataOri = JSON.stringify(res.data.data)
            this.form = res.data.data
        })
        .catch((err) => ErrorService.status(err))
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Verif Master Data Role" }]);
    if (!this.currentUser.menu.verifikasi) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getData()
  },
};
</script>
